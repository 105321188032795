import {inject} from 'aurelia-framework';
import Backend from 'backend';

@inject(Backend)
export class LoggingErrorRenderer {
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  render(message, details = null, stack = null) {
    var logMessage = `${message}: ${details}. Stack trace:\n${stack}`;

    this.backend.RestFrontEndLogController_register({logData: logMessage})
        .catch(error => console.error("Failed to log to backend" + error));
  }
}
