import {inject} from 'aurelia-framework';

import {TicketService} from 'services/ticket-service';

@inject(TicketService)
export class MyTicketsWidget {
  tickets = [];

  constructor(ticketService) {
    ticketService.getMyTickets()
      .then(result => {
        this.tickets = result.items;
      });
  }
}
