import {inject, bindable} from 'aurelia-framework';

import {PersonService} from 'services/person-service';
import {IdentityProvider} from 'devtag-aurelia-auth-plugin';

@inject(PersonService, IdentityProvider)
export class PersonDetails {

  constructor(personService, identityProvider) {
    this.personService = personService;
    this.identityProvider = identityProvider;
  }

  activate(params) {
    return this.personService.getPersonDetails(params.personId)
      .then(person => this.person = person);
  }
}
