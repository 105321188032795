import {inject} from 'aurelia-framework';
import {json} from 'aurelia-fetch-client';
import Backend from 'backend';

import {ActiveEvent} from 'event/active-event';
import {TicketType} from 'models/ticket-type';

@inject(Backend, ActiveEvent)
export class TicketService {
  constructor(backend, activeEvent) {
    this.backend = backend;
    this.activeEvent = activeEvent;
  }

  getTicketTransferData() {
    return this.backend.RestGetTicketTransferDataController_getTicketTransferData(this.activeEvent.id);
  }

  transferTicket(ticketId, personId, resetTicketFromCheckedInToPaidIfNecessary) {
    return this.backend.TransferTicketCommandHandler_handle({
      ticketId: ticketId,
      newOwner: personId,
      resetTicketFromCheckedInToPaidIfNecessary: resetTicketFromCheckedInToPaidIfNecessary
    });
  }

  getMyTickets() {
    return this.backend.GetMyTicketsQueryHandler_execute({ eventId: this.activeEvent.id });
  }

  getTicketTypes() {
    return this.backend.TicketService_getTicketTypes(this.activeEvent.id)
                .then(ticketTypes => Array.from(ticketTypes, t => new TicketType(t)));
  }

  buyAndReserve(seatIds, cardToken, discountCouponIds) {
    return this.backend.PurchaseWithCreditCardCommandHandler_purchase({
          seatIds: seatIds,
          cardToken: cardToken,
          discountCouponIds: discountCouponIds
        });
  }

  buyAndReserveInDoor(seatId, ticketBuyerPersonId) {
    return this.backend.TicketService_purchaseInDoor({
        seatId: seatId,
        ticketBuyerPersonId: ticketBuyerPersonId});
  }
}
