import {inject} from 'aurelia-framework';
import {PersonService} from 'services/person-service';

@inject(PersonService)
export class IndexScreen {
  heading = 'Alle registrerte personer';
  persons = [];

  constructor(personService) {
      this.personService = personService;
  }

  activate() {
      return this.personService.getAllPersons()
        .then(persons => this.persons = persons);
  }
}
