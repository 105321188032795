import {inject} from 'aurelia-framework';
import {ParticipantService} from 'services/participant-service';

@inject(ParticipantService)
export class ParticipantScreen {
  participants = [];

  constructor(participantService) {
    this.participantService = participantService;
  }

  activate() {
    return this.refreshParticipantList();
  }

  refreshParticipantList() {
    return this.participantService.getAllParticipants()
      .then(participants => this.participants = participants);
  }

  replaceWristBand(participant, newWristBandNumber) {
    this.isReplaceWristBandSpinnerVisible = true;
    this.wristBandError = null;

    this.participantService.replaceWristBand(participant.personId, newWristBandNumber)
      .then(() => this.refreshParticipantList())
      .catch(errorPromise => errorPromise.catch(error => this.wristBandError = error.message))
      .then(() => this.isReplaceWristBandSpinnerVisible = false );
  }

  enableReplaceWristBand(participant) {
    participant.replaceWristBandEnabled = true
  }
}
