import {inject} from 'aurelia-framework'
import mapsapi from 'google-maps-api';

@inject(mapsapi('AIzaSyDUtUG1GGK-BVKDL75OCCcxac7UO_uEric'))
export class Venue {
  constructor(mapsApi) {
    this.mapsApi = mapsApi;
  }

  attached() {
    this.mapsApi.then(maps => {
      var mapContainer = this.mapContainer;

      var venueLocation = {lat: 59.413684, lng: 5.404232};
      var map = new maps.Map(mapContainer, {
        center: venueLocation,
        zoom: 13
      })

      var marker = new maps.Marker({
        position: venueLocation,
        map: map
      })
    })
  }
}
