import { bindable, customAttribute, inject } from 'aurelia-framework';

@customAttribute('vui-u-pull-left')
@inject(Element)
export class VuiUPullLeft {
  classes = [
      'vui-u-pull-left'
  ];

  constructor(element) {
    this.element = element;
  }

  attached() {
    this.element.classList.add(...this.classes);
  }

  detached() {
    this.element.classList.remove(...this.classes);
  }
}
