import {inject, NewInstance, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationController} from 'aurelia-validation';
import {I18N} from 'aurelia-i18n';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import {SeatingService} from 'services/seating-service';
import {TicketService} from 'services/ticket-service';
import {DiscountCouponService} from 'services/discount-coupon-service';

@inject(IdentityProvider, Router, NewInstance.of(ValidationController), I18N, SeatingService, TicketService, DiscountCouponService)
export class BuyTicketScreen {
  orderLines = [];
  orderDiscounts = [];

  constructor(identityProvider, router, validationController, i18n, seatingService, ticketService, discountCouponService) {
    this.identityProvider = identityProvider;
    this.router = router;
    this.validationController = validationController;
    this.i18n = i18n;
    this.seatingService = seatingService;
    this.ticketService = ticketService;
    this.discountCouponService = discountCouponService;

    this.confirmPersonalInformation = this.confirmPersonalInformation.bind(this);
    this.buySeat = this.buySeat.bind(this);
  }

  activate(params, routeConfig, navigationInstruction) {
    params.seatIds.forEach(seatId => {
      this.seatingService.getSeatDetails(seatId)
        .then(seat => {
          var error = null;

          if (seat.seatHolder) {
            error = this.i18n.tr('buyTicket.seatAlreadyTakenError');
          }

          if (seat.ticketType.presaleStatus != 'OPEN') {
            error = this.i18n.tr('buyTicket.cannotBuyTicketBecausePresaleIsClosedError');
          }

          this.orderLines.push({
            ticketType: seat.ticketType.ticketName,
            seatId: seat.seatId,
            seatNumber: seat.seatNumber,
            amount: seat.ticketType.currentPrice,
            error: error});

          if (error) {
            this.orderHasError = true;
          }
        });
    })
  }

  confirmPersonalInformation() {
    this.personalInformationConfirmed = true;
  }

  buySeat(creditCardToken) {
    if (!this.personalInformationConfirmed) {
      throw new Error("Personal information must be confirmed before buying ticket");
    }

    if (this.orderLines.some(orderLine => orderLine.error)) {
      throw new Error("One or more of the selected seats are unavailable for purchase")
    }

    var seatIds = this.orderLines.map(orderLine => orderLine.seatId);
    var discountCouponIds = this.orderDiscounts.map(orderDiscount => orderDiscount.discountCouponId);

    return this.ticketService.buyAndReserve(seatIds, creditCardToken, discountCouponIds)
      .then(result => {
        if (result.stripeChargeStatus != 'OK') {
          throw result;
        }
        this.router.navigateToRoute('seatMap');
      })
      .catch(error => {
        // Hacky way to show a better error message for invalid discount coupons
        if (error.message.indexOf('DiscountCouponNotValidException') != -1) {
          error.stripeChargeStatus = 'DISCOUNT_COUPON_NOT_VALID';
          error.exceptionMessage = "Discount coupon is not valid (outdated or already used)";
        }

        throw error;
      });
  }

  useDiscountCoupon() {
    this.discountCouponService
      .getDiscountCouponByCode(this.discountCouponCode)
      .then(discountCoupon => {
        if (discountCoupon == null) {
          this.validationController.addError(this.i18n.tr('buyTicket.discountCouponNotFound'), this, 'discountCouponCode');
          return;
        }

        if (!discountCoupon.valid) {
          this.validationController.addError(this.i18n.tr('buyTicket.discountCouponNotValid'), this, 'discountCouponCode');
          return;
        }

        if (this.orderDiscounts.find(orderDiscount => orderDiscount.discountCouponId == discountCoupon.id)) {
          this.validationController.addError(this.i18n.tr('buyTicket.discountCouponCanOnlyBeUsedOncePerOrder'), this, 'discountCouponCode');
          return;
        }

        this.orderDiscounts.push({ discountCouponId: discountCoupon.id, description: discountCoupon.description, code: discountCoupon.code,  discountAmount: discountCoupon.discountAmount })
        this.discountCouponCode = null;
      });
  }

  @computedFrom('orderLines.length', 'orderDiscounts.length')
  get orderTotal() {
    var orderTotal = 0;

    this.orderLines.forEach(orderLine => orderTotal += orderLine.amount);
    this.orderDiscounts.forEach(orderDiscount => orderTotal -= orderDiscount.discountAmount);

    return Math.max(orderTotal, 0);
  }
}
