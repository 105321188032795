export class ContactUs {
  crewMembers = [
    { name: 'Thomas Kallevik', position: 'LAN:Admin', email: 'mail@destinationsix.no', phoneNumber: '+47 976 79 850'},
    { name: 'Ole-Johan Naustvik', position: 'Tech:Admin', email: 'mail@destinationsix.no', phoneNumber: null},
    { name: 'Kristoffer Kvalevaag', position: 'Game:Admin', email: 'mail@destinationsix.no', phoneNumber: null},
    { name: 'William Thorsen', position: 'Care:Admin', email: 'mail@destinationsix.no', phoneNumber: null},
    { name: 'Ståle Thorsen', position: 'Security:Admin', email: 'mail@destinationsix.no', phoneNumber: null},
    { name: 'Torstein Saltvedt', position: 'Info:Admin', email: 'mail@destinationsix.no', phoneNumber: null}
  ]
}
