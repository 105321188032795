import {inject} from 'aurelia-framework';
import {ActiveEvent} from 'event/active-event';
import {SeatingService} from 'services/seating-service';
import {TicketService} from 'services/ticket-service';
import {CompetitionsService} from 'services/competitions-service';
import {IdentityProvider} from 'devtag-aurelia-auth-plugin';

import {AuthenticationService} from "devtag-aurelia-auth-plugin";

@inject(ActiveEvent, SeatingService, TicketService, CompetitionsService, IdentityProvider, AuthenticationService)
export class Home {
  seatCountDto = {reservedSeatCount: 0, totalSeatCount: 0};
  authenticationService;

  constructor(activeEvent, seatingService, ticketService, competitionsService, identityProvider, authenticationService) {
    this.activeEvent = activeEvent;
    this.identityProvider = identityProvider;
    this.authenticationService = authenticationService;

    seatingService.getSeatCount()
      .then(seatCountDto => this.seatCountDto = seatCountDto);

    ticketService.getTicketTypes()
      .then(ticketTypes => this.standardTicketType = ticketTypes[0]);

    competitionsService.getAllCompetitions()
      .then(competitions => this.competitions = competitions);
  }

  login() {
      this.authenticationService.redirectToLogin();
  }

  attached() {
    this.eventCancelledModal.open();
  }
}
