import {inject, computedFrom, bindable} from 'aurelia-framework';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import {TicketService} from 'services/ticket-service';

@inject(TicketService, IdentityProvider)
export class SeatMapScreen {
  ticketToSwitchSeatFor = null;
  @bindable currentSeat = null;
  selectedSeatsForPurchase = [];
  identityProvider;
  ticketService;

  constructor(ticketService, identityProvider) {
    this.ticketService = ticketService;
    this.identityProvider = identityProvider;
  }

  activate() {
    return this.getMyTickets();
  }

  getMyTickets() {
    return this.ticketService.getMyTickets()
      .then(result => {
        this.myTickets = result.items;
      });
  }

  handleSwitchedSeat(newSeat) {
    this.getMyTickets();
  }

  selectCurrentSeatForPurchase() {
    if (this.currentSeat == null || this.selectedSeatsForPurchase.indexOf(this.currentSeat) != -1) {
      return;
    }

    this.selectedSeatsForPurchase.push(this.currentSeat)
  }

  @computedFrom('seatMap.switchSeatModeEnabled')
  get isAllowedToBuy() {
    if (this.seatMap && this.seatMap.switchSeatModeEnabled) {
      return false;
    }

    return true;
  }

  @computedFrom('selectedSeatsForPurchase.length')
  get selectedSeatIdsForPurchase() {
    return this.selectedSeatsForPurchase.map(seat => seat.seatId);
  }
}
