import {inject} from 'aurelia-framework';
import {Redirect} from 'aurelia-router';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';

@inject(IdentityProvider)
export class RegistrationRedirectStep {
    constructor(identityProvider) {
        this.identityProvider = identityProvider;
    }

    run(navigationInstruction, next) {
        const signUpRoute = 'users/sign-up';

        let hasPerson = this.identityProvider.getPersonId();
        let isNavigatingToSignup = navigationInstruction.config.route === signUpRoute;
        if (this.identityProvider.isCurrentlyLoggedIn() && !hasPerson && !isNavigatingToSignup) {
            return next.cancel(new Redirect(signUpRoute));
        }

        if (hasPerson && isNavigatingToSignup) {
            return next.cancel(new Redirect("dashboard"))
        }

        return next();
    }
}
