import {PLATFORM} from 'aurelia-pal';
import {inject} from 'aurelia-framework';
import {I18N} from 'aurelia-i18n';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import {ActiveEvent} from 'event/active-event';
import {RegistrationRedirectStep} from 'util/registration-redirect-step';

@inject(I18N, IdentityProvider, ActiveEvent)
export class App {
  constructor(i18n, identityProvider, activeEvent) {
    this.i18n = i18n;
    this.identityProvider = identityProvider;
    this.activeEvent = activeEvent;
  }

  configureRouter(config, router) {
    config.title = 'Valkyrie';
    config.options.pushState = true;
    config.addAuthorizeStep(RegistrationRedirectStep);
    config.map([
      // Public routes
      { route: ['', 'home'], name: 'home', moduleId: PLATFORM.moduleName('./home'), nav: false, title: this.i18n.tr('route.home'), public: true },
      { route: 'no-permission', name: 'no-permission', moduleId: PLATFORM.moduleName('./no-permission'), nav: false, title: this.i18n.tr('route.no-permission'), public: true },

      { route: 'competitions', name: 'competitions', moduleId: PLATFORM.moduleName('./cms/competitions/competitions'), nav: true, title: this.i18n.tr('route.competitions'), public: true },
      { route: 'schedule', name: 'schedule', moduleId: PLATFORM.moduleName('./cms/schedule'), nav: true, title: this.i18n.tr('route.schedule'), public: true },
      { route: 'information', name: 'information', moduleId: PLATFORM.moduleName('./cms/information/information-page'), nav: true, title: this.i18n.tr('route.information'), public: true },
      { route: 'contact-us', name: 'contact-us', moduleId: PLATFORM.moduleName('./cms/contact-us'), nav: true, title: this.i18n.tr('route.contact'), public: true },
      { route: 'users/sign-up', name: 'signUp', moduleId: PLATFORM.moduleName('./users/sign-up-screen'), nav: true, title: this.i18n.tr('route.signup'), authOnly: true },



      // Competition rules routes - TODO: Move these into a child router?
      {
        route: 'competitions/:competitionId/rules',
        name: 'showCompetitionRules',
        navigationStrategy: (instruction) => {
          instruction.config.moduleId = PLATFORM.moduleName('./cms/competitions/rules/' + instruction.params.competitionId);

          if (instruction.config.viewPorts) {
            instruction.config.viewPorts.default.moduleId = instruction.config.moduleId;
          }
        },
        public: true
      },

      // User routes
      { route: 'dashboard', name: 'dashboard', moduleId: PLATFORM.moduleName('./dashboard/dashboard-screen'), nav: true, title: this.i18n.tr('route.dashboard'), permission: 'PurchaseTicketPermission' },
      { route: 'persons/:personId/edit', name: 'editPerson', moduleId: PLATFORM.moduleName('./persons/person-edit-screen'), nav: false, title: this.i18n.tr('route.editPerson'), permission: 'EditOwnPersonPermission' },
      { route: 'seat-map', name: 'seatMap', moduleId: PLATFORM.moduleName('./seating/seat-map-screen'), nav: true, title: this.i18n.tr('route.seatMap'), permission: 'PurchaseTicketPermission' },
      { route: 'buy-ticket', name: 'buyTicket', moduleId: PLATFORM.moduleName('./ticket/buy-ticket-screen'), nav: false, title: this.i18n.tr('route.buyTicket'), permission: 'PurchaseTicketPermission' },

      // Admin routes
      { route: 'person', name: 'personIndex', moduleId: PLATFORM.moduleName('./persons/index-screen'), nav: true, title: this.i18n.tr('route.persons'), permission: "GetAllPersonsPermission" },
      { route: 'persons/:personId', name: 'personDetails', moduleId: PLATFORM.moduleName('./persons/person-details-screen'), nav: false, title: this.i18n.tr('route.personDetails'), permission: 'GetAnyPersonDetailsPermission' },
      { route: 'invoice-admin', name: 'invoice-admin', moduleId: PLATFORM.moduleName('./ticket/invoices/invoice-admin'), nav: true, title: this.i18n.tr('route.ticketAdministration'), permission: 'GetAllInvoicesPermission', permissionArgument: this.activeEvent.id },
      { route: 'email-log', name: 'emailLog', moduleId: PLATFORM.moduleName('./email-log'), nav: true, title: this.i18n.tr('route.emailLog'), permission: 'GetEmailLogPermission' },
      { route: 'participants', name: 'participants', moduleId: PLATFORM.moduleName('./participants/participants-screen'), nav: true, title: this.i18n.tr('route.participantList'), permission: 'GetAllParticipantsPermission', permissionArgument: this.activeEvent.id },
      { route: 'ticketTransfer', name: 'ticketTransfer', moduleId: PLATFORM.moduleName('./ticket/ticket-transfer-screen'), nav: true, title: this.i18n.tr('route.ticketTransfer'), permission: 'TransferTicketPermission', permissionArgument: this.activeEvent.id },
      { route: 'checkin', name: 'checkin', moduleId: PLATFORM.moduleName('./checkin/checkin-screen'), nav: true, title: this.i18n.tr('route.checkin'), permission: 'CheckInPermission', permissionArgument: this.activeEvent.id },
      { route: 'discountCoupons', name: 'discountCouponIndex', moduleId: PLATFORM.moduleName('./discount-coupons/index-screen'), nav: true, title: this.i18n.tr('route.discountCoupons'), permission: 'ManageDiscountCouponsPermission', permissionArgument: this.activeEvent.id }
    ]);

    this.router = router;
  }
}
