import {inject, bindable, bindingMode, computedFrom} from 'aurelia-framework';
import {SeatingService} from 'services/seating-service';
import {DtToastService} from 'devtag-aurelia-components-plugin';

@inject(Element, SeatingService, DtToastService)
export class SeatMap {
  seatStatusCssClasses = {
    VACANT: 'seat-map__seat--vacant',
    RESERVED: 'seat-map__seat--reserved',
    PAID_FOR: 'seat-map__seat--paid-for',
    UNAVAILABLE_FOR_SALE: 'seat-map__seat--unavailable-for-sale'
  };

  ticketToSwitchSeatFor = null;
  @bindable currentPersonId;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) currentSeat;

  constructor(element, seatingService, toastService) {
    this.element = element;
    this.seatingService = seatingService;
    this.toastService = toastService;
  }

  attached() {
    this.refresh();
  }

  detached() {
    this.currentSeat = null;
    this.ticketToSwitchSeatFor = null;
  }

  refresh() {
    this.seatingService.getSeatMap(this.currentPersonId)
      .then(seatMap => {
        this.seatMap = seatMap.seatMap;
        this.ticketTypeMap = seatMap.ticketTypeMap;
        this.seatHolderNameMap = seatMap.seatHolderNameMap;
        this.mySeats = seatMap.mySeats;
      });
  }

  isMySeat(seat) {
      return this.mySeats.filter(mySeat => mySeat.seatId === seat.seatId).length > 0;
  }

  enableSwitchSeatModeForTicket(ticket) {
    this.ticketToSwitchSeatFor = ticket;
    this.currentSeat = null;
  }

  switchSeatForChosenTicket(toSeat) {
    // Cannot switch to an unavailable seat
    if (toSeat.seatStatus != 'VACANT') {
      this.toastService.show(this.i18n.tr('seatMap.seatNotAvailableError'), 5000)
      this.currentSeat = null;
      return;
    }

    this.seatingService.switchSeat(this.ticketToSwitchSeatFor.ticketId, this.ticketToSwitchSeatFor.seat.seatId, toSeat.seatId)
      .then(_ => {
        this.ticketToSwitchSeatFor = null;
        this.currentSeat = null;
        this.refresh();

        // Trigger modal open event on the custom element
        let event = new CustomEvent('switched-seat', {
          detail: {
            newSeat: toSeat
          },
          bubbles: true
        });
        this.element.dispatchEvent(event);
      });
  }

  currentSeatChanged(newSeat, oldSeat) {
    // Mark old seat as not chosen
    if (oldSeat) {
      oldSeat.isChoosen = false;
    }

    if (newSeat) {
      // Mark new seat as chosen
      newSeat.isChoosen = true;

      // Set ticket type for seat
      newSeat.ticketType = this.ticketTypeMap[newSeat.ticketTypeId];
      newSeat.seatHolderName = this.seatHolderNameMap[newSeat.seatId];

      if (this.switchSeatModeEnabled) {
        this.switchSeatForChosenTicket(newSeat);
      }
    }
  }

  @computedFrom('ticketToSwitchSeatFor')
  get switchSeatModeEnabled() {
    if (!this.ticketToSwitchSeatFor) {
      return false;
    }

    return true;
  }
}
