import md5 from 'blueimp-md5';

export class GravatarHelper {
  getGravatarUrl(email, fallbackUrl, size) {
    var emailHash = md5(email);

    var baseUrl = `http://www.gravatar.com/avatar/${emailHash}`;

    var queryParameters = new Map();
    if (fallbackUrl) {
      queryParameters.set('default', fallbackUrl);
    }

    if (size) {
      queryParameters.set('size', size);
    }

    let url = baseUrl;
    if (queryParameters.size > 0) {
      url = url + '?';
      for (let [key, value] of queryParameters) {
        url = url + key + "=" + value + "&";
      }
      url = url.slice(0, -1);
    }

    return encodeURI(url);
  }
}
