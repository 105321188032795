import {inject, NewInstance, bindable} from 'aurelia-framework';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {I18N} from 'aurelia-i18n';

import {PersonService} from 'services/person-service';
import {Person} from 'models/person';
import {ActiveEvent} from 'event/active-event';
import moment from 'moment';

@inject(PersonService, NewInstance.of(ValidationController), ActiveEvent, I18N)
export class PersonEditForm {
  @bindable personId;
  @bindable successCallback;
  @bindable submitLabel = "Submit";
  @bindable submitDescription;

  person = new Person({ address: {}, phoneNumber: {}, user: {}, guardian: { phoneNumber: {} } });

  constructor(personService, validationController, activeEvent, i18n) {
    this.personService = personService;
    this.validationController = validationController;
    this.activeEvent = activeEvent;
    this.i18n = i18n;
  }

  bind() {
    return this.personService.getPersonDetails(this.personId)
      .then(person => {
        this.person = person;

        // TODO: Fix this
        // We need to add nested object to the person to avoid having the validation crash
        if (!this.person.address) this.person.address = { };
        if (!this.person.phoneNumber) this.person.phoneNumber = {};
        if (!this.person.guardian) this.person.guardian = { phoneNumber: {} };

        ValidationRules
          .ensure('firstName')
            .displayName(this.i18n.tr('persons.edit.firstNameLabel'))
            .required()
          .ensure('lastName')
            .displayName(this.i18n.tr('persons.edit.lastNameLabel'))
            .required()
          .ensure('nickName')
            .displayName(this.i18n.tr('persons.edit.nickNameLabel'))
            .required()
          //.ensure('email').required().email() THIS CANNOT BE EDITED
          .ensure('birthDate')
            .displayName(this.i18n.tr('persons.edit.birthDateLabel'))
            .required()
            .then()
            .satisfies(value => moment(value, 'YYYY-MM-DD', true).isValid())
              .withMessage(this.i18n.tr('persons.edit.birthDateFormatValidationError'))
            .then()
            .satisfies(value => moment(value).isBefore(moment(this.activeEvent.minimumBirthDate)))
              .withMessage(this.i18n.tr('persons.edit.birthDateAgeValidationError', { ageLimit: this.activeEvent.ageLimit }))
          .on(this.person);

        ValidationRules
          .ensure('streetAddress')
            .displayName(this.i18n.tr('persons.edit.streetAddressLabel'))
            .required()
          .ensure('postalCode')
            .displayName(this.i18n.tr('persons.edit.postalCodeLabel'))
            .required()
          .ensure('city')
            .displayName(this.i18n.tr('persons.edit.cityLabel'))
            .required()
          // TODO: Add validation rules for these fields when they are added to the template
          //.ensure('person.address.stateProvince').isNotEmpty().hasMinLength(3)
          //.ensure('person.address.country').isNotEmpty().hasMinLength(3)
          .on(this.person.address);

        ValidationRules
          // TODO: Add validation rules for these fields when they are added to the template
          //.ensure('person.phoneNumber.countryCode').isNotEmpty().containsOnlyDigits()
          .ensure('number')
            .displayName(this.i18n.tr('persons.edit.phoneNumberLabel'))
            .required()
            .matches(/[0-9]*/).minLength(3)
          .on(this.person.phoneNumber);

        ValidationRules
          .ensure('firstName')
            .displayName(this.i18n.tr('persons.edit.guardianFirstNameLabel'))
            .required()
          .ensure('lastName')
            .displayName(this.i18n.tr('persons.edit.guardianLastNameLabel'))
            .required()
          .on(this.person.guardian)

        ValidationRules
          // TODO: Add validation rules for these fields when they are added to the template
          //.ensure('person.guardian.phoneNumber.countryCode').isNotEmpty().containsOnlyDigits()
          .ensure('number')
            .displayName(this.i18n.tr('persons.edit.guardianPhoneNumberLabel'))
            .required()
            .matches(/[0-9]*/)
            .minLength(3)
          .on(this.person.guardian.phoneNumber);
      });
  }

  submit() {
    this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }

        this.personService.updatePersonDetails(this.person)
          .then(() => {
            this.updateSuccessful = true;
            if (typeof this.successCallback === 'function') {
              this.successCallback();
            }
          })
          .catch(error => {
            // Hacky email not unique validation from server-side
            if (error.message.indexOf('UsernameNotUniqueException') != -1) {
              this.validationController.addError(this.i18n.tr('signup.nicknameAlreadyTakenError'), this.user, 'username')
            }

            // Hacky username not unique validation from server-side
            if (error.message.indexOf('EmailNotUniqueException') != -1) {
              this.validationController.addError(this.i18n.tr('signup.emailAlreadyUsedError'), this.user, 'email')
            }
          });
      });
  }
}
