import {inject} from 'aurelia-framework';
import {json} from 'aurelia-fetch-client';

import Backend from 'backend';
import {ActiveEvent} from 'event/active-event';
import {TicketType} from 'models/ticket-type';

@inject(Backend, ActiveEvent)
export class SeatingService {
  constructor(backend, activeEvent) {
    this.backend = backend;
    this.activeEvent = activeEvent;
  }

  getSeatCount() {
    return this.backend.SeatingService_getSeatCount(this.activeEvent.id)
  }

  getSeatDetails(seatId) {
    return this.backend.SeatingService_getSeatDetails(seatId)
    .then(seat => {
      seat.ticketType = new TicketType(seat.ticketType);

      return seat;
    });
  }

  getSeatMap(ticketBuyerPersonId) {
    return this.backend.RestSeatMapController_getSeatMap(ticketBuyerPersonId, this.activeEvent.id)
      .then(seatMap => {
        Object.keys(seatMap.ticketTypeMap).map((key) => {
          seatMap.ticketTypeMap[key] = new TicketType(seatMap.ticketTypeMap[key]);
        });

        return seatMap;
      });
  }

  switchSeat(ticketId, fromSeatId, toSeatId) {
    return this.backend.SeatingService_switchSeat(ticketId, toSeatId);
  }
}
