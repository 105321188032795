import {inject, NewInstance} from 'aurelia-framework';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {I18N} from 'aurelia-i18n';
import {Person} from 'models/person';
import {AuthenticationService, IdentityProvider} from "devtag-aurelia-auth-plugin";
import Backend from "../backend";
import {Router} from "aurelia-router";
import {BindingSignaler} from 'aurelia-templating-resources';

@inject(NewInstance.of(ValidationController), I18N, IdentityProvider, Backend, Router, AuthenticationService, BindingSignaler)
export class SignUpScreen {
  user = new Person(); /** FYI, server-side most of this objects fields are on the person entity */
  identityProvider;
  backend;
  router;
  authenticationService;
  signaler;

  constructor(validationController, i18n, identityProvider, backend, router, authenticationService, signaler) {
    this.validationController = validationController;
    this.i18n = i18n;
    this.identityProvider = identityProvider;
    this.backend = backend;
    this.router = router;
    this.authenticationService = authenticationService;
    this.signaler = signaler;
  }

  activate() {
    this.user.firstName = this.identityProvider.getFirstName();
    this.user.lastName = this.identityProvider.getLastName();
    this.user.nickname = this.identityProvider.getUsername();
    this.user.email = this.identityProvider.getEmail();
  }

  attached() {
    ValidationRules
      .ensure('firstName')
        .displayName(this.i18n.tr('signup.firstNameLabel'))
        .required()
      .ensure('lastName')
        .displayName(this.i18n.tr('signup.lastNameLabel'))
        .required()
      .ensure('nickname')
        .displayName(this.i18n.tr('signup.nicknameLabel'))
        .required()
      .ensure('email')
        .displayName(this.i18n.tr('signup.email'))
        .required()
        .email()
      .on(this.user);
  }

  submit() {
    this.validationController.validate()
      .then(result => {
          if (!result.valid) {
              return;
          }

        this.backend.SignUpController_signUp(this.user)
          .then(() => this.refreshAndRedirect())
          .catch(error => {
            if (error.exceptionName === 'NicknameNotUniqueException') {
              this.validationController.addError(this.i18n.tr('signup.nicknameAlreadyTakenError'), this.user, 'nickname')
            }

            if (error.exceptionName === 'EmailNotUniqueException') {
              this.validationController.addError(this.i18n.tr('signup.emailAlreadyUsedError'), this.user, 'email')
            }

            // If user already has person just precede as normal. User isn't going to notice the difference anyway.
            if (error.exceptionName === 'UserAlreadyHasPersonException') {
              this.refreshAndRedirect();
            }
          });
      });
  }

  refreshAndRedirect() {
    this.authenticationService.refreshUserInfo()
        .then(() => {
            this.signaler.signal('permissionsChanged');
            return this.router.navigateToRoute("dashboard");
        });
  }
}
