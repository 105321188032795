import {inject, bindable} from 'aurelia-framework';
import Backend from 'backend';


import {computedFrom} from 'aurelia-framework';

import {ActiveEvent} from 'event/active-event';
import {Person} from 'models/person';
import {TicketService} from 'services/ticket-service';

@inject(Backend, ActiveEvent, TicketService)
export class CheckInScreen {
  @bindable currentSeat = null;

  constructor(backend, activeEvent, ticketService) {
    this.backend = backend;
    this.activeEvent = activeEvent;
    this.ticketService = ticketService;
  }

  submitSeatNumberQuery(seatNumber) {
    this.queryForCheckInDetails(this.backend.RestCheckInBySeatNumberQueryController_checkInQueryUrl(seatNumber, this.activeEvent.id));
  }

  submitTicketReferenceQuery(ticketReference) {
    this.queryForCheckInDetails(this.backend.RestCheckInByTicketReferenceQueryController_checkInQueryUrl(ticketReference, this.activeEvent.id));
  }

  queryForCheckInDetails(queryPromise) {
    this.clearEverythingForNewSearch();

    queryPromise
      .then(checkInDto => {
        if (checkInDto == null) {
          this.showNotFoundMessage = true;
          return;
        }
        this.chooseCheckIn(checkInDto);
      });
  }

  submitPersonNameQuery(personName) {
    this.clearEverythingForNewSearch();

    this.backend.RestCheckInByPersonNameController_checkInQueryUrl(personName, this.activeEvent.id)
      .then(checkInDtos => {
        if (checkInDtos.length == 0) {
          this.showNotFoundMessage = true;
          return;
        }
        this.searchResultCheckInDtos = checkInDtos;
        if (this.searchResultCheckInDtos.length == 1) {
          this.chooseCheckIn(this.searchResultCheckInDtos[0]);
        }
      });
  }

  chooseCheckIn(checkIn) {
    this.person = new Person(checkIn.person);
    this.tickets = checkIn.tickets;
  }

  clearEverythingForNewSearch() {
    this.searchResultCheckInDtos = [];
    this.isCheckInConfirmVisible = false;
    this.isCheckInSpinnerVisible = false;
    this.wristBandError = null;
    this.person = null;
    this.tickets = null;
    this.wristBandNumber = null;
    this.currentSeat = null;
  }

  submitWristBand(wristBandNumber) {
    this.isCheckInConfirmVisible = false;
    this.isCheckInSpinnerVisible = true;
    this.wristBandError = null;

    this.backend
      .CheckInWristBandCommandHandler_handle({ personId: this.person.id, wristBandNumber: wristBandNumber, eventId: this.activeEvent.id })
      .then(_ => this.isCheckInConfirmVisible = true)
      .catch(error => this.wristBandError = error.exceptionName + ': ' + error.message)
      .then(_ => this.isCheckInSpinnerVisible = false);

    this.wristBandNumber = null;
  }

  buySeatInDoor(seat) {
    this.ticketService.buyAndReserveInDoor(seat.seatId, this.person.id)
        .then(_ => {
          this.submitSeatNumberQuery(seat.seatNumber);  // Refresh the view to show the new seatnumber for the ticket.
        });
  }

  handleSwitchedSeat(newSeat) {
    this.submitSeatNumberQuery(newSeat.seatNumber); // Refresh the view to show the new seatnumber for the ticket.
  }

  @computedFrom('tickets')
  get isWristBandPanelVisible() {
    return this.tickets
      && this.tickets.length > 0
      && this.tickets.some(ticket => ticket.ticketStatus == 'PAID')
      && !this.tickets.some(ticket => ticket.ticketStatus == 'CHECKED_IN');
  }
}
