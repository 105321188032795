export class CompetitionsService {
  getAllCompetitions() {
    // Return "To be announced" result
    return new Promise((resolve, reject) => {
      resolve({
        totalPrizeMoney: null,
        items: null
      })
    })

    return new Promise((resolve, reject) => {
      resolve({
        totalPrizeMoney: 40000,
        items: [
          {
            id: 'cs-go',
            name: 'Counter-Strike: GO',
            //imageUrl: 'images/competitions/csgo.png',
            imageUrl: 'http://media.steampowered.com/steamcommunity/public/images/items/730/29c471623f64f8ef1a175fb341d2d79233958592.jpg',
            signUpUrl: 'https://battlefy.com/haneso/destination-six-2017-csgo/583253f9d7b84a33037e0310/info',
            playersPerTeam: 5,
            totalPrizeMoney: 20000,
            prizeMoney: [
              15000,
              5000
            ]
          },
          {
            id: 'lol',
            name: 'League of Legends',
            //imageUrl: 'images/competitions/lol.png',
            imageUrl: 'http://euw.leagueoflegends.com/sites/default/files/styles/scale_xlarge/public/upload/lol_promoart_4.jpg?itok=N9XVhEpY',
            signUpUrl: 'https://battlefy.com/haneso/destination-six-2017-league-of-legends/58324ccfb2446f3503efd1a1/info',
            playersPerTeam: 5,
            totalPrizeMoney: 12500,
            prizeMoney: [
              10000,
              2500
            ]
          },
          {
            id: 'hearthstone',
            name: 'HearthStone',
            //imageUrl: 'images/competitions/hearthstone.png',
            imageUrl: 'http://media.blizzard.com/hearthstone/images/backgroundart.jpg',
            signUpUrl: 'https://battlefy.com/haneso/destination-six-2017-hearthstone/58324f46f70da6bb078af3e5/info',
            playersPerTeam: 1,
            totalPrizeMoney: 3000,
            prizeMoney: [
              2000,
              1000
            ]
          },
          {
            id: 'rocket',
            name: 'Rocket League',
            //imageUrl: 'images/competitions/rocket.png',
            imageUrl: 'http://deepcheats.com/wp-content/uploads/2016/05/rocket-league-hack.jpg',
            signUpUrl: 'https://battlefy.com/haneso/destination-six-2017-rocket-league/583251edf70da6bb078af41d/info',
            playersPerTeam: 3,
            totalPrizeMoney: 4500,
            prizeMoney: [
              3000,
              1500
            ]
          }
        ]
      });
    });
  }
}
