import {computedFrom} from 'aurelia-framework';
import moment from 'moment';

export class TicketType {
  constructor(data) {
    Object.assign(this, data);
  }

  get presaleStatus() {
    if (this.presale) {
      return this.presale.status;
    }

    return 'NOT_AVAILABLE';
  }
}
