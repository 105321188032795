import {bindable, inject} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import {GravatarHelper} from 'util/gravatar-helper';

import {AuthenticationService} from "devtag-aurelia-auth-plugin";

@inject(IdentityProvider, GravatarHelper, BindingSignaler, AuthenticationService)
export class Navigation {
  @bindable router = null;
  authenticationService;
  signaler;
  sidenavHeader;

  constructor(identityProvider, gravatarHelper, signaler, authenticationService) {
    this.identityProvider = identityProvider;
    this.gravatarHelper = gravatarHelper;
    this.authenticationService = authenticationService;
    this.signaler = signaler;
  }

  logout() {
    this.authenticationService.redirectToLogOut();
  }

  toggleSidenavHeaderMenu() {
    this.sidenavHeader.classList.toggle('active');
    if (this.sidenavHeaderMenu.style.display === '') {
      this.sidenavHeaderMenu.style.display = 'block';
    } else {
      this.sidenavHeaderMenu.style.display = '';
    }
  }
}
