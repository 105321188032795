export class InformationPage {
  configureRouter(config, router) {
    config.map([
      { route: '', name: 'base', moduleId: PLATFORM.moduleName('./base'), nav: false, title: null },
      { route: 'general-rules', name: 'generalRules', moduleId: PLATFORM.moduleName('./general-rules'), nav: true, title: 'Generelle regler' },
      { route: 'technical', name: 'technical', moduleId: PLATFORM.moduleName('./technical'), nav: true, title: 'Teknisk' },
      { route: 'check-list', name: 'checkList', moduleId: PLATFORM.moduleName('./check-list'), nav: true, title: 'Huskeliste' },
      { route: 'competitions', name: 'competitions', moduleId: PLATFORM.moduleName('./competitions'), nav: true, title: 'Konkurranser' },
      { route: 'kiosk', name: 'kiosk', moduleId: PLATFORM.moduleName('./kiosk'), nav: true, title: 'Mat/kiosk' },
      { route: 'venue', name: 'venue', moduleId: PLATFORM.moduleName('./venue'), nav: true, title: 'Hvor arrangeres datatreffet?' },
      { route: 'payment', name: 'payment', moduleId: PLATFORM.moduleName('./payment'), nav: true, title: 'Betaling' },
      { route: 'visitation', name: 'visitation', moduleId: PLATFORM.moduleName('./visitation'), nav: true, title: 'Besøkspass' },
    ]);

    this.router = router;
  }
}
