import {inject} from 'aurelia-framework';

import Backend from 'backend';

import {DiscountCoupon} from 'models/discount-coupon';
import {ActiveEvent} from 'event/active-event';

@inject(Backend, ActiveEvent)
export class DiscountCouponService {
  backend;
  activeEvent;

  constructor(backend, activeEvent) {
    this.backend = backend;
    this.activeEvent = activeEvent;
  }

  getDiscountCouponByCode(code) {
    return this.backend.GetDiscountCouponByCodeQueryHandler_execute({
         discountCode: code,
         eventId: this.activeEvent.id
       })
      .then(data => {
        if (data == null) {
          return null;
        }

        return new DiscountCoupon(data);
      });
  }

  getAllDiscountCoupons() {
    return this.backend.GetAllDiscountCouponsQueryHandler_execute({ eventId: this.activeEvent.id });
  }

  createDiscountCoupon(discountCoupon) {
    discountCoupon.eventId = this.activeEvent.id;

    return this.backend.CreateDiscountCouponCommandHandler_handle(discountCoupon);
  }
}
