import {inject} from 'aurelia-framework';
import Backend from 'backend';
import {ActiveEvent} from 'event/active-event';

@inject(ActiveEvent, Backend)
export class InvoiceService {
  activeEvent;
  backend;

  constructor(activeEvent, backend) {
    this.activeEvent = activeEvent;
    this.backend = backend;
  }

  getMyInvoice() {
    return this.backend.TicketService_getMyInvoices(this.activeEvent.id);
  }

  getAllInvoices() {
    return this.backend.RestInvoiceAdminController_getInvoices(this.activeEvent.id);
  }

  registerPayment(invoiceId) {
    return this.backend.RestInvoiceAdminController_registerPayment(invoiceId);
  }

  cancelTicket(ticketId) {
    return this.backend.RestInvoiceAdminController_cancelTicket(ticketId);
  }

  addNote(ticketId, note) {
    return this.backend.RestInvoiceAdminController_addNote(ticketId, note);
  }
}
