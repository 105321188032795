import {inject} from 'aurelia-framework';
import {DiscountCouponService} from 'services/discount-coupon-service';

@inject(DiscountCouponService)
export class IndexScreen {
  discountCoupons = [];

  constructor(discountCouponService) {
      this.discountCouponService = discountCouponService;

      this.fetchDiscountCoupons = this.fetchDiscountCoupons.bind(this);
  }

  activate() {
    return this.fetchDiscountCoupons();
  }

  fetchDiscountCoupons() {
    return this.discountCouponService.getAllDiscountCoupons()
      .then(result => this.discountCoupons = result.items);
  }
}
