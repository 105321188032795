import {inject} from 'aurelia-framework';
import Backend from 'backend';
import moment from 'moment';

@inject(Backend)
export class ActiveEvent {
  backend;
  whenLoaded;

  constructor(backend) {
    this.backend = backend;

    // Fetch the active event, and assign the data as properties to this class
    this.whenLoaded = new Promise((resolve, reject) => {
      this.backend.GetActiveEventQueryHandler_handle({})
        .then(data => Object.assign(this, data))
        .then(_ => resolve())
    });
  }

  get minimumBirthDate() {
    return moment(this.startDate)
      .subtract(this.ageLimit, 'years')
      .toDate();
  }
}
