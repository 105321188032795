import {inject} from 'aurelia-framework';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';

@inject(IdentityProvider)
export class PublicOnlyRouteFilterValueConverter {
  constructor(identityProvider) {
    this.identityProvider = identityProvider;
  }

  toView(routes){
    var filteredRoutes = routes.filter(route => (route.config.public || route.config.nonAuthOnly) && this.identityProvider.hasPermissionToRoute(route));

    return filteredRoutes
  }
}
