import {inject} from 'aurelia-framework';
import {HttpClient} from 'aurelia-fetch-client';
import 'whatwg-fetch';
import {json} from 'aurelia-fetch-client';
import {Configurations} from 'devtag-aurelia-config-plugin'

@inject(HttpClient, Configurations)
export class ApiClient {
  apiRoot;
  accessToken;

  constructor(httpClient, config) {
    this.apiRoot = config.getConfig('apiRoot');
    this.httpClient = httpClient;
    this.configure();
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
    this.configure();
  }

  clearAccessToken() {
    this.setAccessToken(null);
    this.configure();
  }

  configure() {
    this.httpClient.configure(config => {
      // config.useStandardConfiguration();
      config.withBaseUrl(this.apiRoot);
      config.rejectErrorResponses();
      if(this.accessToken) {
        config.withDefaults({
          headers: {
            'Authorization': 'Bearer ' + this.accessToken
          }
        });
      }
    })
  }

  invoke(className, methodName, args) {
    return this.httpClient
        .fetch('/' + className + "/" + methodName,
            {
              method: 'POST',
              body: json(args)
            })

        // Error responses
        .catch(error => {
          if (error instanceof Error) {
            let message;
            if (error.message === 'Failed to fetch') {
              message = "Kunne ikke koble til server."
            } else {
              message = error.message;
            }
            throw new ApiError(message, false);
          }
          // If status code is 400 (bad request)
          else if (error.status === 400) {
            throw new ApiError("Got status code " + error.statusText + " from " + error.url, false);
          }
          // If status code is 401 (unauthorized)
          else if (error.status === 401) {
            throw new ApiError("Got status code " + error.statusText + " from " + error.url, false);
          }
          // If status code is 403 (forbidden)
          else if (error.status === 403) {
            throw new ApiError("Got status code " + error.statusText + " from " + error.url, false);
          }
          // If status code is 500 (Internal server error)
          else if (error.status === 500) {
            // Deserialize anyway, because it's a serialized exception from
            // the back end, wrap it in a standardized error type and throw it.
            return error
                .json()
                .then(json => new ApiError(
                    json.message,
                    true,
                    json.throwable,
                    json.throwableClassName,
                    json.throwableClassSimpleName
                ))
                .then(error => {
                  // Note: Even though we can return a promise from a promise handler
                  // and expect it to be resolved before the next then() is called,
                  // we can't _throw_ a promise and expect the same.
                  // See https://stackoverflow.com/a/45890388/5377597
                  throw error;
                });
          }
          throw new ApiError("ApiClient has no handling for error status code: " + error.status + " from url: " + error.url, false);
        })

        // Http status 2xx
        .then(response => {
          // 200 OK => response body is json.
          if (response.status === 200) {
            // Deserialize and return the payload. json() returns a promise,
            // but returning a promise from a promise handler works fine.
            return response.json();
          }
          // 204 NO_CONTENT => response body is empty because return type is void.
          else if (response.status === 204) {
            return;
          }

          // Should never get here.
          throw new Error("Only status 200 and 204 are supported OK responses, so this isn't expected.")
        });
  }
}

export class ApiError {
  message;
  isExceptionFromBackend;
  exception;
  exceptionFullClassName;
  exceptionName;

  constructor(message, isExceptionFromBackend, exception, exceptionFullClassName, exceptionName) {
    this.message = message;
    this.isExceptionFromBackend = isExceptionFromBackend;
    this.exception = exception;
    this.exceptionFullClassName = exceptionFullClassName;
    this.exceptionName = exceptionName;
  }

  toString() {
    if (this.isExceptionFromBackend) {
      return "Exception from backend: " 
          + this.exceptionFullClassName + "\n"
          + this.message;
    } else {
      return this.message;
    }
  }
}
