import {inject} from 'aurelia-framework'
import {CompetitionsService} from 'services/competitions-service';
import {TicketService} from 'services/ticket-service';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';

@inject(CompetitionsService, TicketService, IdentityProvider)
export class Competitions {
  currentUserHasTicket = false;

  constructor(competitionsService, ticketService, identityProvider) {
    competitionsService.getAllCompetitions()
      .then(competitions => this.competitions = competitions);

    // Get tickets to find out if the current user should be able to see the sign up links
    if (identityProvider.isLoggedIn) {
      ticketService.getMyTickets()
        .then(result => {
          this.currentUserHasTicket = result.items.length > 0
        });
    }
  }
}
