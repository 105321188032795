import '../styles/styles.scss';

import '@babel/polyfill';
import * as Bluebird from 'bluebird';

// Config
import {configurationProfiles} from 'config/configurationProfiles'
import {configurationProfiles as configurationProfilesDevOverrides} from 'config/dev-overrides'
import {Configurations} from 'devtag-aurelia-config-plugin'

// Auth
import {OidcBackend, IdentityProvider} from "devtag-aurelia-auth-plugin";
import {ApiClient} from 'api-client';
import Backend from "backend";

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({ warnings: { wForgottenReturn: false } });

import {I18N} from 'aurelia-i18n';
import I18NXhrBackend from 'i18next-xhr-backend';
import {I18NErrorMessageProvider} from 'util/error-handling/i18n-error-message-provider';
import {ValidationMessageProvider} from 'aurelia-validation';
import {Router} from 'aurelia-router';
import {ActiveEvent} from 'event/active-event';
import moment from 'moment';
import 'moment/locale/nb';
import numeral from 'numeral';
import 'numeral/locales/no';
import {ToastErrorRenderer} from "devtag-aurelia-components-plugin";
import {LoggingErrorRenderer} from "./util/error-handling/logging-error-renderer";

Object.assign(
    IdentityProvider.prototype,
    {
      getPersonId() {
        return this.claims.personId;
      }
    }
);

export async function configure(aurelia) {
  var errorHandler; // Used to store the error handler, so that it can be modified later

  // Set the momentjs locale
  moment.locale('nb');

  // Set the numeraljs locale
  numeral.locale('no');

  // Override validation messages, to have translation
  ValidationMessageProvider.prototype.getMessage = function(key) {
    const i18n = aurelia.container.get(I18N);
    const translation = i18n.tr(`validationErrorMessages.${key}`);
    return this.parser.parse(translation);
  };

  ValidationMessageProvider.prototype.getDisplayName = function(propertyName, displayName) {
    if (displayName !== null && displayName !== undefined) {
      return displayName;
    }
    const i18n = aurelia.container.get(I18N);
    return i18n.tr(propertyName);
  };

  // Configure Aurelia
  await aurelia.use
      .standardConfiguration()
      .developmentLogging()
      .plugin(PLATFORM.moduleName('devtag-aurelia-config-plugin'), config => config.profiles([configurationProfiles, configurationProfilesDevOverrides]))
      .plugin(PLATFORM.moduleName('devtag-aurelia-error-handling-plugin'), config => {
        let toastErrorRenderer = aurelia.container.get(ToastErrorRenderer);
        let loggingErrorRenderer = aurelia.container.get(LoggingErrorRenderer);
        config.addRenderer(toastErrorRenderer);
        config.addRenderer(loggingErrorRenderer);
        errorHandler = config; // Store the error handler reference to be able to modify it later
      })
      .plugin(PLATFORM.moduleName('devtag-aurelia-permission-gui-plugin'), config => {
        config.backend = aurelia.container.get(Backend);
        config.backendMethodPrefix = 'LocalPermissionService_';
      })
      .plugin(PLATFORM.moduleName('devtag-aurelia-auth-plugin'), config => {
        const router = aurelia.container.get(Router);
        const configurations = aurelia.container.get(Configurations);

        const authorityUrl = configurations.getConfig('authAuthorityUrl');
        const appBaseUrl = configurations.getConfig('authAppBaseUrl');

        config.authBackend = new OidcBackend(authorityUrl, appBaseUrl, appBaseUrl, router);

        // This callback fires when _everything_ is done. It's loaded even if additional claims weren't set. But not if no user is logged in.
        config.userLoadedCallback = user => {
          // Set token on api client.
          const apiClient = aurelia.container.get(ApiClient);
          apiClient.setAccessToken(user.access_token);
        };

        // Signout
        config.userSignedOutCallback = _ => {
          // Clear token from api client
          const apiClient = aurelia.container.get(ApiClient);
          apiClient.clearAccessToken();

          // Redirect to home
          aurelia._started.then(() => {
            router.navigateToRoute('home');
          });
        };

        config.urlToRedirectToAfterLogin = '#/dashboard';
        config.authorizedHomeRoute = '';
        config.noPermissionRoute = 'no-permission';
      })

      // Enable Aurelia Validation pluging
      .plugin(PLATFORM.moduleName('aurelia-validation'))

      // Enable Aurelia i18n plugin
      .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
        // Register backend plugin
        instance.i18next.use(I18NXhrBackend);

        // Configure i18next
        return instance.setup({
          lng: 'no',
          fallbackLng: 'en'
        });
      })

      // Register Devtag UI components for global use
      .feature(PLATFORM.moduleName('components/index'))
      .feature(PLATFORM.moduleName('devtag-aurelia-components-plugin/index'))
  ;

  await aurelia.start();

  // Wait until the active event is loaded before starting the application
  var activeEvent = aurelia.container.get(ActiveEvent);
  await activeEvent.whenLoaded;

  await aurelia.setRoot(PLATFORM.moduleName('app'));

  // Configure the error handling to use i18n for messages
  var i18n = aurelia.container.get(I18N);
  errorHandler.setErrorMessageProvider(new I18NErrorMessageProvider(i18n));
}
