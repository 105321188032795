import {computedFrom} from 'aurelia-framework';
import moment from 'moment';

export class Person {
  constructor(data) {
    Object.assign(this, data);
  }

  @computedFrom('birthDate')
  get isUnderAged() {
    var birthDate = moment(this.birthDate, 'YYYY-MM-DD', true);

    if (birthDate.isValid()) {
      var age = moment().diff(birthDate, 'years')

      return age < 18;
    }

    return true;
  }
}
