import {inject, NewInstance, bindable} from 'aurelia-framework';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {I18N} from 'aurelia-i18n';

import {DiscountCoupon} from 'models/discount-coupon';
import {DiscountCouponService} from 'services/discount-coupon-service';
import moment from 'moment';

@inject(DiscountCouponService, NewInstance.of(ValidationController), I18N)
export class DiscountCouponCreateForm {
  @bindable successCallback;

  discountCoupon = new DiscountCoupon();

  constructor(discountCouponService, validationController, i18n) {
    this.discountCouponService = discountCouponService;
    this.validationController = validationController;
    this.i18n = i18n;
  }

  attached() {
    ValidationRules
      .ensure('code').displayName(this.i18n.tr('discountCoupons.code')).required()
      .ensure('description').displayName(this.i18n.tr('discountCoupons.description')).required()
      .ensure('discountAmount').displayName(this.i18n.tr('discountCoupons.discountAmount')).required()
      .ensure('validFromDate')
        .satisfies(value => !value || moment(value, 'YYYY-MM-DD', true).isValid())
          .withMessage(this.i18n.tr('discountCoupons.create.invalidPeriodError'))
      .ensure('validToDate')
        .satisfies(value => !value || moment(value, 'YYYY-MM-DD', true).isValid())
          .withMessage(this.i18n.tr('discountCoupons.create.invalidPeriodError'))
      .ensure('maxNumberOfUses').displayName(this.i18n.tr('discountCoupons.maxNumberOfUses')).required()
      .on(this.discountCoupon);
  }

  submit() {
    this.validationController.validate()
      .then(result => {
        if (!result.valid) {
            return;
        }

        this.discountCouponService.createDiscountCoupon(this.discountCoupon)
          .then(() => {
            this.createSuccessful = true;
            if (typeof this.successCallback === 'function') {
              this.successCallback();
            }
            this.discountCoupon = new DiscountCoupon();
          })
          .catch(error => {
            // Hacky email not unique validation from server-side
            if (error.message.indexOf('DuplicateDiscountCouponCodeException') != -1) {
              this.validationController.addError(this.i18n.tr('discountCoupons.create.discountCouponCodeAlreadyUsedError'), this.discountCoupon, 'code')
            }
          });
      });
  }
}
