import {bindable, inject} from 'aurelia-framework';
import {Configurations} from 'devtag-aurelia-config-plugin'

@inject(Configurations)
export class StripeComponent {
  number;
  cvc;
  exp_month;
  exp_year;

  cardTokenError;
  paymentError;
  @bindable paymentDisabled;
  @bindable paymentDisabledText;
  @bindable paymentAction;
  @bindable isProcessingPayment;

  constructor(configuration) {
    Stripe.setPublishableKey(configuration.getConfig('stripePublishableKey'));
  }

  detached() {
    this.isProcessingPayment = false;
  }

  submit() {
    this.isProcessingPayment = true;

    Stripe.card.createToken({
      number: this.number,
      cvc: this.cvc,
      exp_month: this.exp_month,
      exp_year: this.exp_year
    }, (status, response) => this.stripeResponseHandler(status, response));
  }

  stripeResponseHandler(status, response) {
    if (response.error) {
      this.isProcessingPayment = false;
      this.cardTokenError = response.error.message;
    } else {
      var token = response.id;
      this.paymentAction(token)
        .catch(error => {
          this.isProcessingPayment = false;
          this.paymentError = error.stripeChargeStatus + ": " + error.exceptionMessage;
        });
    }
  }
}
