import {inject} from 'aurelia-framework';

import Backend from 'backend';
import {Person} from 'models/person';

@inject(Backend)
export class PersonService {
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  getAllPersons() {
    return this.backend.GetAllPersonsQueryHandler_getAll();
  }

  getPersonDetails(personId) {
    return this.backend.GetPersonDetailsQueryHandler_getPersonDetails(personId)
      .then(data => {
        return new Person(data);
      });
  }

  updatePersonDetails(person) {
    return this.backend.EditPersonCommandHandler_editPerson(person);
  }
}
