import {inject} from 'aurelia-framework';
import Backend from 'backend';

import {ActiveEvent} from 'event/active-event';

@inject(Backend, ActiveEvent)
export class ParticipantService {
  backend;
  constructor(backend, activeEvent) {
    this.backend = backend;
    this.activeEvent = activeEvent;
  }

  getAllParticipants() {
    return this.backend.RestGetAllParticipantsQueryController_getAllParticipants(this.activeEvent.id);
  }

  replaceWristBand(personId, newWristBandNumber) {
    return this.backend.ReplaceWristBandCommandHandler_handle({
          eventId: this.activeEvent.id,
          newWristBandNumber: newWristBandNumber,
          personId: personId
      });
  }
}
