import {inject} from 'aurelia-framework';
import {json} from 'aurelia-fetch-client';
//

// //import {HttpClient} from 'util/http-client';

// @inject(HttpClient)
export class EmailLog {
  emailLog = [];

  // constructor(http) {
  //   http.fetch('emailLog', {
  //       method: 'get'
  //     })
  //     .then(response => response.json())
  //     .then(emailLog => this.emailLog = emailLog);
  // }
}
