import {inject } from 'aurelia-framework';
import {TicketService} from 'services/ticket-service';

@inject(TicketService)
export class TicketTransferScreen {
  constructor(ticketService) {
    this.ticketService = ticketService;
  }

  activate() {
    return this.ticketService.getTicketTransferData()
      .then(data => {
        this.ticketsAvailableForTransfer = data.ticketsAvailableForTransfer;
        this.allPersons = data.allPersons;
      });
  }

  chooseTicket(ticket) {
    this.chosenTicket = ticket;
    this.transferStatus = null
  }

  choosePerson(person) {
    this.chosenPerson = person;
    this.transferStatus = null
  }

  transferTicket() {
    this.transferStatus = "Overfører ...";
    this.ticketService
      .transferTicket(this.chosenTicket.ticketId, this.chosenPerson.id, this.resetTicketFromCheckedInToPaidIfNecessary)
      .then(() => {
        this.chosenTicket = null;
        this.chosenPerson = null;
        this.transferStatus = "OK: Billetten er overført."
      })
      .catch(error => {
          return this.transferStatus = "FEIL: " + error;
      });
  }
}
