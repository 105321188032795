import {inject} from 'aurelia-framework';

import {InvoiceService} from 'services/invoice-service';

@inject(InvoiceService)
export class InvoiceAdmin {
  invoices = [];
  newNote = [];
  openInvoice = null;
  activeFilter = null;

  constructor(invoiceService) {
    this.invoiceService = invoiceService;

    this.activeFilter = this.showUnpaid;
    this.reloadInvoices();
  }

  reloadInvoices() {
    this.invoiceService.getAllInvoices()
      .then(invoices => {

        this.invoices = invoices.invoices;
        this.seating = invoices.seating;
        this.persons = invoices.persons;

        this.activeFilter();
      })
  }

  registerPayment(invoiceId) {
    this.invoiceService.registerPayment(invoiceId)
                     .then(_ => this.reloadInvoices());
  }

  cancelTicket(ticketId) {
    this.invoiceService.cancelTicket(ticketId)
                     .then(_ => this.reloadInvoices());
  }

  addNote(invoice) {
    this.invoiceService.addNote(invoice.ticketId, invoice.newNote)
                     .then(_ => {
                       this.reloadInvoices();
                       invoice.newNote = "";
                     });
  }

  showInvoice(invoice) {
    this.openInvoice = invoice;
    window.scrollTo(0,0);
  }

  closeOpenInvoice() {
    this.openInvoice = null;
  }

  applyFilter(filterFunction) {
    this.activeFilter = filterFunction;
    this.activeFilter();
  }

  showAll() {
    this.invoices.forEach(i => {
      i.hidden = false;
    });
  }

  showUnpaid() {
    this.invoices.forEach(i => {
      i.hidden = false;
      if (i.ticketStatus != 'UNPAID') {
        i.hidden = true;
      }
    })
  }


  showOverdue() {
    this.invoices.forEach(i => {
      i.hidden = false;
      if (i.daysUntilDueDate > 0 || i.ticketStatus != 'UNPAID') {
        i.hidden = true;
      }
    })
  }

  showPaid() {
    this.invoices.forEach(i => {
      i.hidden = false;
      if (i.ticketStatus != 'PAID') {
        i.hidden = true;
      }
    })
  }
}
